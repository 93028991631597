import { Component } from 'react';
import { Route, BrowserRouter, Redirect} from 'react-router-dom';
import routes from './routes';
import {Switch} from "react-router";
function checkAuth() {
  return false;
}

function App({...rest}) {
  return (
      <BrowserRouter>
          <Route {...rest}
                 render={props => (
                     checkAuth()
                         ? ( <Component {...props} /> )
                         : ( <Redirect to="/login" />
                         )
                 )}
          />
          <Switch>
              {routes.map((item,idx)=>
                  ( <Route key={idx} path ={item.path} component={() => item.element}/> ))}
          </Switch>
      </BrowserRouter>
  );
}
export default App;