import React, { Component } from "react";
// import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
// import { authSaga } from "../redux/sagas/auth";

class Login extends Component {

    static propType ={
        login : PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    }
    state = {
        username: "",
        password: "",
    };
    componentDidMount() {
        // store.dispatch(authSaga());
    }
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmitAccount = (e) =>{
        e.preventDefault();
        // this.props.authSaga(this.state.email, this.state.password)
    };

    render() {
        if(this.props.isAuthenticated){
            return <Redirect to = "/"/>;
        }
        const {email, password} = this.state;
        return (
            <Container>
                <Input
                    id="email"
                    name="email"
                    placeholder="아이디를 입력해주세요"
                    onChange={this.onChange}
                />
                <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    onChange={this.onChange}
                />
                <Button onClick={this.onSubmitAccount}>로그인</Button>
                <div>{email}</div>
                <div>{password}</div>
            </Container>
        );
    }
}
// const mapStateToProps = state => ({
//     isAuthenticated: state.auth.isAuthenticated
// });

// eslint-disable-next-line no-undef
// export default connect(mapStateToProps, { authSaga })(Login);
export default Login;
// export default Login;
const Container = styled.div`
  margin-top: 100px;
  padding: 20px;
`;

const Input = styled.input`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  margin: 0 0 8px;
  padding: 5px 39px 5px 11px;
  border: solid 1px #dadada;
  background: #fff;
  box-sizing: border-box;
`;

const Button = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 49px;
  display: block;
  width: 100%;
  height: 49px;
  margin: 16px 0 7px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 0;
  background-color: #03c75a;
  ${({disabled}) =>
    disabled &&
    `
    background-color: #efefef;
  `}
`;